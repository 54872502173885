import { createApp } from 'vue';
import { loadFonts } from './plugins/webfontloader';
import { getCookie, setCookie } from './services/cookies';

loadFonts();
import('./bootstrapInvestorPortal').then((bootstrap) => {
  createApp(bootstrap.App)
    .use(bootstrap.router)
    .use(bootstrap.store)
    .use(bootstrap.vuetify)
    .mount('#container');

  // Triggers an event if the token is sent (this is important for debugging and iframing)
  window.addEventListener('message', (event) => {
    if (event.data && event.data.eventName && event.data.eventName === 'iframe__parent__SendJwtMessage') {
      bootstrap.store.dispatch('setJWT', event.data.token);
    }
  });

  // For local debugging - lets post a message with a mocked JWT
  if (process.env.DEBUG && process.env.DEBUG_JWT) {
    const token = getCookie('token');
    if (!token) setCookie('token', process.env.DEBUG_JWT);

    window.postMessage({
      eventName: 'iframe__parent__SendJwtMessage',
      token: token || process.env.DEBUG_JWT,
    }, '*');
  }
});
