export function getCookie(name) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

export function setCookie(name, value, daysToExpire) {
  let expires = '';
  if (daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);
    expires = `; expires=${expirationDate.toUTCString()}`;
  }

  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
  document.cookie = cookieString;
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
